@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap);
body{margin:0;font-family:"Raleway", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background:black;color:white;overflow:hidden}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}

.ComingSoon_root__2OCOh{position:relative;height:100vh;display:flex;flex-direction:column}.ComingSoon_comingSoon__3-HC6{padding-right:20px;transition:color 0.4s ease-in-out}.ComingSoon_comingSoon__3-HC6:hover{color:white}.ComingSoon_logo__1gdwY{flex:1 1;display:flex;flex-direction:column;justify-content:center;align-items:center}.ComingSoon_logoAnchor__3WSZ9{width:90%;height:194px;margin:40px 0;max-height:50vh}.ComingSoon_davinci__RJ03j{position:absolute;left:50%;top:50%;transform:translate(-50%, -50%)}.ComingSoon_davinciAnchor__2Og-y{width:70vw;height:72vh}.ComingSoon_about__odMM-{font-size:32px;line-height:1.4em;max-width:1000px}

.Canvas_canvas__3uh42,.Canvas_foreground__1mtiR,.Canvas_background__tVu_G{position:fixed;left:0;top:0;width:100vw;height:100vh;background:transparent;pointer-events:none}.Canvas_foreground__1mtiR{z-index:100;box-shadow:inset 0 0 100px black}.Canvas_background__tVu_G{z-index:0}.Canvas_content__2Ni_z{position:relative;z-index:50}

.Scroller_root__1S7sY{color:white}


.Cursor_wrapper__2s5Tm{cursor:none}.Cursor_inner__aAFif{z-index:100;position:fixed;width:100px;height:100px;left:0;top:0;mix-blend-mode:difference;pointer-events:none;display:flex;justify-content:center;align-items:center}.Cursor_outer__B9udv{z-index:100;position:fixed;width:100px;height:100px;left:0;top:0;mix-blend-mode:difference;pointer-events:none;display:flex;justify-content:center;align-items:center;transition:transform 0.1s linear}

